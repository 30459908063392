<style>
.ivu-table .table-invalid-row td {
  color: #777 !important;
}
.ivu-table .ivu-table-row-hover td {
  color: #aaa !important;
}
</style>
<template>
  <div>
    <Divider dashed><span class="divider-text">合同基础信息</span></Divider>
    <Row class="p-b-10">
      <i-col span="6" class="title">合同编号 </i-col>
      <i-col span="18">
        {{ contract.relateCode }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="6" class="title">合同名称 </i-col>
      <i-col span="18">
        {{ contract.relateName }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="6" class="title">甲方 </i-col>
      <i-col span="18">
        {{ contract.payerAccountName }}
      </i-col>
    </Row>
    <div v-show="isEdit">
      <Divider dashed><span class="divider-text">收付款录入</span></Divider>
      <Row class="p-b-10">
        <i-col span="6" class="title">录入类型 </i-col>
        <i-col span="18">
          <div
            @click="handleChangeType(1)"
            :class="
              BaseData.direction === 1
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
            class="p-l-20 p-r-20"
          >
            收款
          </div>
          <div
            @click="handleChangeType(-1)"
            :class="
              BaseData.direction === -1
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
            class="p-l-20 p-r-20"
          >
            付款
          </div>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="6" class="title">日期 </i-col>
        <i-col span="18">
          <DatePicker
            transfer
            class="text-black"
            format="yyyy-MM-dd"
            size="small"
            :clearable="false"
            v-model="BaseData.receiveDate"
            style="width: 100%"
            placeholder="默认选中当日"
          ></DatePicker>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="6" class="title">金额 </i-col>
        <i-col span="18">
          <Input
            prefix="logo-yen"
            size="small"
            number
            v-model="BaseData.receiveAmount"
            placeholder="输入录入金额"
            style="width: 100%"
          />
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="6" class="title">备注 </i-col>
        <i-col span="18">
          <Input
          v-model="BaseData.mark"
          type="textarea"
          maxlength="500"
          class="p-t-5"
          :autosize="{minRows: 3,maxRows: 5}"
          placeholder="请输入备注信息"
        />
        </i-col>
      </Row>

      <Row>
        <i-col span="24">
        <Upload multiple type="drag" :before-upload="handleBeforeUploadFile" action>
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
            <p>点击或拖拽文件到这里进行上传</p>
          </div>
        </Upload>
        <Row v-show="fileList.length">
          <i-col span="14">
            <b>文件名称</b>
          </i-col>
          <i-col span="5">
            <b>文件大小</b>
          </i-col>
          <i-col span="5">
            <b>操作</b>
          </i-col>
        </Row>
        <hr  v-show="fileList.length" />
        <Row v-for="(file,fileIndex) in fileList" :key="fileIndex" v-show="fileList.length">
          <i-col span="14">{{file.name}}</i-col>
          <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col>
          <i-col span="5">
            <a class="delete" @click="handleRemove(file)">删除</a>
          </i-col>
          <i-col span="24">
            <hr />
          </i-col>
        </Row>
      </i-col>
      </Row>

      <Row class="p-t-20">
        <i-col class="text-right p-r-10" >
          <Button type="success" size="small" @click="adddebtgroupcashflow"
            >确认录入</Button
          >
        </i-col>
      </Row>
    </div>

    <Divider dashed><span class="divider-text">合同收付详情</span></Divider>
    <Table
      stripe
      :data="contractList"
      :columns="tableColumns"
      :row-class-name="rowClassName"
    ></Table>
    <Modal
      v-model="beginUpload"
      width="400"
      :styles="{top: '250px'}"
      :mask-closable="false"
      :closable="false"
      footer-hide
    >
      <h3 class="text-center">上传文件中..</h3>
      <Progress :percent="percentage" :stroke-width="20" status="active" text-inside></Progress>
    </Modal>
    <Modal  v-model="filesModal"
    title="附件列表"
      width="600"
      :mask-closable="false"
      footer-hide>
      <Files
            :fileList="fileArray"
            :downloadUrl="'/ooh-scp/v3/debtgroupcashflow/downloadFile'"
          ></Files>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import Files from '@/components/common/FilesV2'
import { getStorage } from '@/utils/storage'
import { toMoney } from '@/utils/wnumb_own'
import {
  getdebtgroupcashflowpage,
  invaliddebtgroupcashflow
} from '@/api/scp/debtgroupcashflow'
export default {
  // props: {
  //   isEdit: Boolean,
  //   contract: Object
  // },

  components: { Files },
  data () {
    return {
      BaseData: {
        direction: 1,
        receiveAmount: 0,
        mark: '',
        receiveDate: new Date()
      },
      fileList: [],
      beginUpload: false,
      percentage: 0,
      contractList: [],
      tableColumns: [
        {
          title: '收付日期',
          key: 'date'
        },
        {
          title: '收付金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(params.row.amount))
            ])
          }
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '收付类型',
          key: 'directionName',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  style: {
                    color: params.row.direction === 1 ? '#44bd32' : '#ef4f4f'
                  }
                },
                params.row.directionName
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              params.row.cashflowFileVOList.length
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.fileArray = params.row.cashflowFileVOList.map(x => {
                          return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v3/debtgroupcashflow/downloadFile?fileId=' + x.id })
                        })

                        this.filesModal = true
                        // this.handleInvalid(params.row)
                      }
                    }
                  },
                  '附件信息'
                )
                : null,
              params.row.status === 1
                ? h(
                  'a',
                  {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.handleInvalid(params.row)
                      }
                    }
                  },
                  '作废'
                )
                : h(
                  'span',
                  {
                    style: { marginRight: '5px' }
                  },
                  '已作废'
                )
            ])
          }
        }
      ],

      filesModal: false,
      fileArray: []
    }
  },
  created () {
    if (this.contract) {
      this.initdebtgroupcashflow()
    }
  },
  methods: {
    rowClassName (row, index) {
      if (row.status === -1) {
        return 'table-invalid-row'
      }
      return ''
    },
    handleChangeType (val) {
      this.BaseData.direction = val
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    adddebtgroupcashflow () {
      var formatAmount = toMoney(this.BaseData.receiveAmount)
      if (formatAmount === '¥ 0.00') {
        this.$Notice.info({ desc: '请确认您的金额输入是否正确' })
      } else {
        this.$Modal.confirm({
          title: '确认录入一笔金额为' + formatAmount + '的收付信息？',
          onOk: () => {
            this.beginUpload = true
            this.percentage = 0
            // 开始批量执行上传操作
            const that = this
            const uploadData = new FormData()
            uploadData.append('debtType', this.contract.debtType)
            uploadData.append('relateId', this.contract.relateId)
            uploadData.append(
              'receiveDate',
              this.formatDate(
                this.BaseData.receiveDate
              )
            )
            uploadData.append('receiveAmount', this.BaseData.receiveAmount)
            uploadData.append('mark', this.BaseData.mark)

            this.fileList.forEach(function (file, index) {
              uploadData.append('files', file)
            })

            axios
              .post(
                process.env.VUE_APP_API_URL_V2 +
                '/ooh-scp/v3/debtgroupcashflow/adddebtgroupcashflow',
                uploadData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'ooh-auth': getStorage('ooh-token')
                  },
                  withCredentials: true,
                  onUploadProgress (progress) {
                  // 处理上传文件进度条数据
                    that.percentage = Math.round(
                      (progress.loaded / progress.total) * 100
                    )
                  }
                }
              )
              .then((res) => {
                this.beginUpload = false
                if (res && res.data && !res.data.errcode) {
                  this.$Notice.success({ desc: '录入成功' })
                  this.BaseData = {
                    direction: 1,
                    receiveAmount: 0,
                    mark: '',
                    receiveDate: new Date(this.BaseData.receiveDate)
                  }
                  this.initdebtgroupcashflow()
                  this.$store.commit('set_payment_update', new Date())
                } else {
                  this.$Notice.error({ desc: res.data.errmsg })
                }
              })

            // this.BaseData.debtType = this.contract.debtType
            // this.BaseData.relateId = this.contract.relateId
            // this.BaseData.receiveDate = this.formatDate(
            //   this.BaseData.receiveDate
            // )
            // adddebtgroupcashflow(this.BaseData).then((res) => {
            //   if (res && !res.errcode) {
            //     this.$Notice.success({ desc: '录入成功' })
            //     this.BaseData = {
            //       direction: 1,
            //       receiveAmount: 0,
            //       receiveDate: new Date(this.BaseData.receiveDate)
            //     }
            //     this.initdebtgroupcashflow()
            //     this.$store.commit('set_payment_update', new Date())
            //   }
            // })
          }
        })
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    handleInvalid (item) {
      let desc = ''
      this.$Modal.confirm({
        title: '作废收付信息？',
        render: (h) => {
          return h('Input', {
            attrs: {
              placeholder: '请输入作废原因'
            },
            props: {
              value: desc
            },
            on: {
              'on-change': (event) => {
                desc = event.target.value
              }
            }
          })
        },
        onOk: () => {
          invaliddebtgroupcashflow({
            cashflowId: item.id,
            invalidMark: desc
          }).then((res) => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '作废成功' })
              this.initdebtgroupcashflow()
              this.$store.commit('set_payment_update', new Date())
            }
          })
        }
      })
    },
    initdebtgroupcashflow () {
      var query = {
        pageNumber: 1,
        pageSize: 1000,
        relateId: this.contract.relateId,
        debtType: this.contract.debtType
      }
      getdebtgroupcashflowpage(query).then((res) => {
        this.contractList = res.list
      })
    }
  },
  watch: {
    contract (val) {
      this.BaseData = {
        direction: 1,
        receiveAmount: 0,
        receiveDate: new Date()
      }
      if (val) {
        this.initdebtgroupcashflow()
      }
    }
  },
  computed: {
    contract () {
      return this.$store.state.payment
        ? this.$store.state.payment.contract
        : {}
    },
    isEdit () {
      return this.$store.state.payment
        ? this.$store.state.payment.isEdit
        : false
    }
  }
}
</script>
